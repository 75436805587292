import { Collections } from '@looxr/constants';
import ApiService from './ApiService';
import AppStateService from './AppStateService';
import FirebaseService from './FirebaseService';

const CustomerService = {
  async processFormData({
    isCreate,
    customerData,
    customerRef,
    formData,
    contactImage,
    brandImage,
    parentRef
  }) {
    try {
      const update = { ...customerData, ...formData };

      let id = customerRef ? customerRef.id : null;

      if (isCreate) {
        update.parent = parentRef;
        update.createdAt = FirebaseService.getTimestamp();
        update.createdBy = AppStateService.user.ref;
      }

      if (!update.contact) {
        update.contact = {};
      }

      if (!update.brand) {
        update.brand = {};
      }

      update.contact.image = contactImage;
      update.brand.qrLogo = brandImage;

      const result = await FirebaseService.upsertDoc(id, update, Collections.customer);

      if (result) {
        // fetch new created customer to get customer doc and have the ref to process other data
        if (isCreate) {
          id = result;

          const newCustomerDoc = await FirebaseService.ref
            .firestore()
            .collection(Collections.customer)
            .doc(id)
            .get();

          await CustomerService.createLocationSummary(newCustomerDoc);

          return {
            id,
            doc: newCustomerDoc,
            data: newCustomerDoc.data()
          };
        }

        return {
          id,
          doc: customerRef,
          data: update
        };
      }

      return false;
    } catch (e) {
      console.log(e);
      return false;
    }
  },

  async createLocationSummary(customerDoc) {
    try {
      const summaryData = {
        airLoss: 0,
        customer: customerDoc.ref,
        emission: 0,
        energyConsumption: 0,
        energyCosts: 0,
        energyCostsSaving: 0,
        leaksTotal: 0,
        repairedLeaksTotal: 0
      };

      await FirebaseService.upsertDoc(null, summaryData, Collections.leakLocationSummary);
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  },

  async handlePackageValidUntilChanges(customerPackage, usersOfPackage) {
    const packageType = customerPackage.type.toLowerCase().includes('leak') ? 'leak' : 'pressure';
    const userLoginType = packageType === 'leak' ? 'leakLogin' : 'pressureLogin';

    for (let i = 0; i < usersOfPackage.length; i += 1) {
      const userDoc = usersOfPackage[i];

      // console.log('package valid until => ', customerPackage.validUntil);
      // console.log('user login valid until => ', customerPackage.validUntil);
      // console.log(customerPackage.validUntil.seconds);
      // console.log(userDoc[userLoginType].validUntil.seconds);

      // console.log('user doc => ', userDoc);

      if (customerPackage.validUntil.seconds !== userDoc[userLoginType].validUntil.seconds) {
        const userUpdate = { ...userDoc };
        userDoc[userLoginType].validUntil = customerPackage.validUntil;

        const userID = userUpdate.id;

        // Remove id and ref from doc like object to only save actual data
        delete userUpdate.id;
        delete userUpdate.ref;

        await FirebaseService.upsertDoc(userID, userUpdate, Collections.user);
      }
    }

    return true;
  },

  async handleUsersOfArchivedPackage(customerPackage, customerData, usersOfPackage) {
    const packageType = customerPackage.type.toLowerCase().includes('leak') ? 'leak' : 'pressure';

    if (customerData.type === 'TYPE_CUSTOMER') {
      for (let j = 0; j < usersOfPackage.length; j += 1) {
        const userDoc = usersOfPackage[j];
        const userUpdate = { ...userDoc };
        let archiveUser = true;

        // console.log(' ');
        // console.log('Package of Customer => ', customerData.name1);
        // console.log('Users Of Package => ', userDocs.length);
        // console.log('package type => ', packageType);
        // console.log('user doc => ', userDoc);
        // console.log('userUpdate => ', userUpdate);

        // check if user has another login/package valid
        if (
          (packageType === 'leak' && userUpdate.logins.pressure === true) ||
          (packageType === 'pressure' && userUpdate.logins.leak === true)
        ) {
          archiveUser = false;
        }

        try {
          userUpdate.logins[packageType] = false;

          if (packageType === 'leak') {
            userUpdate.leakLogin.package = null;
          }

          if (packageType === 'pressure') {
            userUpdate.pressureLogin.package = null;
          }

          // console.log('Removed package and login from User => ', userUpdate.email);

          if (archiveUser) {
            // make sure to reset all even if we checked before
            userUpdate.logins.pressure = false;
            userUpdate.logins.leak = false;

            userUpdate.leakLogin.package = null;
            userUpdate.pressureLogin.package = null;

            userUpdate.archived = true;
            userUpdate.archivedAt = FirebaseService.getTimestamp();

            // unlicense the onelogin user
            const result = await ApiService.unlicenseUser({
              email: userUpdate.email,
              oneloginID: userUpdate.oneloginID
            });

            if (result !== false) {
              console.log('Archived user => ', userUpdate.email);
            } else {
              console.log('FAILED to arvhive user => ', userUpdate.email);
            }
          }

          // Remove id and ref from doc like object to only save actual data
          const userID = userUpdate.id;

          delete userUpdate.id;
          delete userUpdate.ref;

          await FirebaseService.upsertDoc(userID, userUpdate, Collections.user);
        } catch (e) {
          console.log('Error archiving user => ', e);
        }
      }
    }

    return true;
  }
};

export default CustomerService;
