import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import {
  Banner,
  Box,
  Button,
  ContentBox,
  DateRangeInput,
  Label,
  LoadingOverlay,
  Select,
  SortTable,
  TextInput
} from '@looxr/components';
import { useLanguage, useTranslation } from '@looxr/utils';
import {
  getArchivedFilterOptions,
  getPackageFilterOptions,
  getUserListTableConfig
} from '../../constants';
import { useFilterUsers, useLoadUsers, useUserFilter } from '../../hooks';

const StyledFilterBox = styled(Box)`
  & > * {
    margin-left: 10px;
  }
  &:first-child {
    margin-left: 0;
  }
`;
function UserList() {
  const language = useLanguage();
  const tn = useTranslation();
  const history = useHistory();
  const { getUsers } = useLoadUsers();
  const { filterUsers } = useFilterUsers();
  const { updateFilterField, storeFilter, resetFilter, filter, loadingFilter } = useUserFilter();

  const [userTableConfig, setUserTableConfig] = useState(
    getUserListTableConfig({ showArchivedAt: filter.isArchived === 'true' })
  );
  const [initialFilterApplied, setInitialFilterApplied] = useState(false);
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [userTableData, setUserTableData] = useState([]);

  // Effect to load initial data
  useEffect(() => {
    const load = async () => {
      setLoading(true);
      const userData = await getUsers(filter);
      setUsers(userData);
      setUserTableData(userData);
      setLoading(false);
    };

    load();
  }, []);

  // Effect to apply initial filter on load
  useEffect(() => {
    if (!loading && !loadingFilter && !initialFilterApplied) {
      const filteredData = filterUsers(filter, users);
      const config = getUserListTableConfig({ showArchivedAt: filter.isArchived === 'true' });

      setUserTableConfig(config);
      setUserTableData(filteredData);
      setInitialFilterApplied(true);
    }
  }, [filter, users, loading, loadingFilter, initialFilterApplied]);

  const applyFilter = (e) => {
    if (e) {
      e.preventDefault();
    }

    const filtered = filterUsers(filter, users);

    storeFilter();
    const config = getUserListTableConfig({ showArchivedAt: filter.isArchived === 'true' });
    setUserTableConfig(config);
    setUserTableData(filtered);
  };

  const resetFilterData = () => {
    resetFilter();
    const config = getUserListTableConfig({ showArchivedAt: filter.isArchived === 'true' });
    setUserTableConfig(config);
    setUserTableData(users);
  };

  const editUser = (data) => {
    history.push(`/user/${data.id}`);
  };

  return (
    <>
      <Banner
        text={tn('admin.page.userList.title')} // "Benutzer"
      />
      <Box paddingX={10} paddingY={10} minHeight="calc(100vh - 143px)">
        <form onSubmit={applyFilter}>
          <ContentBox title="Filter">
            <StyledFilterBox display="flex" direction="row" wrap="wrap">
              <Box width={200} marginBottom={3}>
                <Label htmlFor="filter-firstname">
                  {tn('general.firstname')}
                  {/* Vorname */}
                </Label>
                <TextInput
                  id="filter-firstname"
                  name="filter-firstname"
                  value={filter.firstname ? filter.firstname : ''}
                  onChange={(e) => updateFilterField('firstname', e.currentTarget.value)}
                />
              </Box>
              <Box width={200} marginBottom={3}>
                <Label htmlFor="filter-lastname">
                  {tn('general.lastname')}
                  {/* Nachname */}
                </Label>
                <TextInput
                  id="filter-lastname"
                  name="filter-lastname"
                  value={filter.lastname ? filter.lastname : ''}
                  onChange={(e) => updateFilterField('lastname', e.currentTarget.value)}
                />
              </Box>
              <Box width={200} marginBottom={3}>
                <Label htmlFor="filter-email">
                  {tn('general.email')}
                  {/* E-Mail */}
                </Label>
                <TextInput
                  id="filter-email"
                  name="filter-email"
                  value={filter.email ? filter.email : ''}
                  onChange={(e) => updateFilterField('email', e.currentTarget.value)}
                />
              </Box>
              <Box width={200} marginBottom={3}>
                <Label htmlFor="filter-customer">
                  {tn('general.customer')}
                  {/* Kunde */}
                </Label>
                <TextInput
                  id="filter-customer"
                  name="filter-customer"
                  value={filter.customerName ? filter.customerName : ''}
                  onChange={(e) => updateFilterField('customerName', e.currentTarget.value)}
                />
              </Box>
              <Box width={200} marginBottom={3}>
                <Label htmlFor="filter-hasLeak">
                  {tn('admin.page.userList.filter.leakPackage')}
                  {/* Leckage Paket */}
                </Label>
                <Select
                  id="filter-hasLeak"
                  name="filter-hasLeak"
                  value={filter.hasLeak ? filter.hasLeak : 'all'}
                  options={getPackageFilterOptions()}
                  onChange={(e) => updateFilterField('hasLeak', e.value)}
                />
              </Box>
              <Box width={200} marginBottom={3}>
                <Label htmlFor="filter-hasPressure">
                  {tn('admin.page.userList.filter.pressurePackage')}
                  {/* Druckluft Paket */}
                </Label>
                <Select
                  id="filter-hasPressure"
                  name="filter-hasPressure"
                  value={filter.hasPressure ? filter.hasPressure : 'all'}
                  options={getPackageFilterOptions()}
                  onChange={(e) => updateFilterField('hasPressure', e.value)}
                />
              </Box>
              <Box width={200} marginBottom={3}>
                <Label htmlFor="filter-leak-valid">
                  {tn('admin.page.userList.filter.leakValidUntil')}
                  {/* Leckage Gültigkeit */}
                </Label>
                <DateRangeInput
                  locale={language}
                  name="filter-leak-valid"
                  range={filter.leakValidRange ? filter.leakValidRange : { from: null, to: null }}
                  onChange={(update) => updateFilterField('leakValidRange', update)}
                />
              </Box>
              <Box width={200} marginBottom={3}>
                <Label htmlFor="filter-pressure-valid">
                  {tn('admin.page.userList.filter.pressureValidUntil')}
                  {/* Druckluft Gültigkeit */}
                </Label>
                <DateRangeInput
                  locale={language}
                  name="filter-pressure-valid"
                  range={
                    filter.pressureValidRange ? filter.pressureValidRange : { from: null, to: null }
                  }
                  onChange={(update) => updateFilterField('pressureValidRange', update)}
                />
              </Box>
              <Box width={200} marginBottom={3}>
                <Label htmlFor="filter-isArchived">{tn('general.archived')}</Label>
                <Select
                  id="filter-isArchived"
                  name="filter-isArchived"
                  value={filter.isArchived}
                  options={getArchivedFilterOptions()}
                  onChange={(e) => updateFilterField('isArchived', e.value)}
                />
              </Box>

              <Box width="100%" marginTop={6}>
                <Box display="flex" direction="row">
                  <Box width={120}>
                    <Button
                      size="sm"
                      type="submit"
                      text={tn('general.filter')} // "Filtern"
                      onClick={applyFilter}
                    />
                  </Box>

                  <Box width={120} marginX={3}>
                    <Button
                      size="sm"
                      text={tn('general.reset')} // "Zurücksetzen"
                      background="purple"
                      onClick={resetFilterData}
                    />
                  </Box>
                </Box>
              </Box>
            </StyledFilterBox>
          </ContentBox>

          <ContentBox
            title={tn('admin.page.userList.table.title')} // "Benutzer"
            noPadding
            marginTop={20}
            showAddBtn={true}
            onAddClick={() => history.push('/user/new')}
          >
            <SortTable
              tableKey="users"
              data={userTableData}
              hasPagination={true}
              pagerCondensed={false}
              perPage={20}
              saveState={true}
              noDataText={tn('admin.page.userList.table.noData')} // "Keine Benutzerdaten zu diesen Kriterien vorhanden"
              dataName={tn('admin.page.userList.table.title')} // "Benutzer"
              columns={userTableConfig}
              hasRowHover={true}
              onRowClick={editUser}
              defaulSortBy="lastname"
              defaultSortOrder="asc"
            />
          </ContentBox>
        </form>
      </Box>
      <LoadingOverlay isVisible={loading} />
    </>
  );
}

export default UserList;
