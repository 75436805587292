import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import NavigationPrompt from 'react-router-navigation-prompt';
import styled from 'styled-components';
import {
  Alert,
  Banner,
  Box,
  Button,
  Info,
  LoadingBox,
  Modal,
  Popup,
  TeaserBox,
  Text
} from '@looxr/components';
import { Collections } from '@looxr/constants';
import { useTranslation } from '@looxr/utils';
import { getCustomerDefault } from '../../constants';
import {
  useLoadCustomers,
  useLoadUsers,
  usePackages,
  useUpdateChildMap,
  useUserFilter
} from '../../hooks';
import { ApiService, AppStateService, CustomerService, FirebaseService } from '../../services';
import CustomerFormBrand from './CustomerFormBrand';
import CustomerFormContact from './CustomerFormContact';
import CustomerFormGeneral from './CustomerFormGeneral';
import CustomerFormPackages from './CustomerFormPackages';
import CustomerFormParams from './CustomerFormParams';
import CustomerFormPrivacy from './CustomerFormPrivacy';
import CustomerPackageForm from './CustomerPackageForm';
import CustomerTreeGraph from './CustomerTreeGraph';

const StyledFormButtonBox = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;

  button:first-child {
    margin-right: 20px;
  }
`;

function CustomerForm() {
  const tn = useTranslation();

  const { getUsersByCustomer } = useLoadUsers();

  const { register, handleSubmit, errors, reset, watch, clearError, formState } = useForm({
    defaultValues: getCustomerDefault()
  });

  const { updateFilter, resetFilter } = useUserFilter();

  const history = useHistory();
  const location = useLocation();
  const { id } = useParams();

  const { getCustomerById } = useLoadCustomers();
  const { updateParent } = useUpdateChildMap();
  const { getPackagesOfCustomer, getUsersByPackage, updateCustomerPackages } = usePackages();

  const [loading, setLoading] = useState(true);
  const [isCreate, setIsCreate] = useState(true);

  const [showTreeGraphModal, setShowTreeGraphModal] = useState(false);
  const [showPackageModal, setShowPackageModal] = useState(false);
  const [showArchivePopup, setShowArchivePopup] = useState(false);

  const [customerRef, setCustomerRef] = useState(null);
  const [customerData, setCustomerData] = useState(null);

  const [parentRef, setParentRef] = useState(null);
  const [parentData, setParentData] = useState(null);

  const [packages, setPackages] = useState([]);
  const [editPackage, setEditPackage] = useState({});

  const [maxUserCount, setMaxUserCount] = useState(0);
  const [activeUserCount, setActiveUserCount] = useState(0);

  const [contactPersonImageSrc, setContactPersonImageSrc] = useState(null);
  const [brandQrLogoImageSrc, setBrandQrLogoImageSrc] = useState(null);

  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showMaxUserPopup, setShowMaxUserPopup] = useState(false);

  // Effect to load initial data
  useEffect(() => {
    const load = async () => {
      setLoading(true);

      const searchString = location.search ? location.search.replace('?', '') : null;
      let searchParams = null;
      if (searchString) {
        searchParams = new URLSearchParams(searchString);
      }

      let create = false;
      let customerDoc = null;
      let customerPackages = [];
      let activeUserCount = 0;
      let maxUserCount = 0;
      let customerDocData = getCustomerDefault();
      let customerParentRef = AppStateService.customer.ref;
      let customerParentDoc = null;
      let customerParentData = null;

      if (id !== 'new') {
        customerDoc = await getCustomerById(id);

        if (customerDoc) {
          const userOfCustomerSnapshot = await getUsersByCustomer(customerDoc.ref);
          userOfCustomerSnapshot.forEach((doc) => {
            const docData = doc.data();
            if (!docData.archived === true) {
              activeUserCount += 1;
            }
          });

          customerDocData = customerDoc.data();
          customerParentRef = customerDocData.parent ? customerDocData.parent : null;

          if (customerParentRef) {
            customerParentDoc = await customerParentRef.get();
          }

          customerPackages = await getPackagesOfCustomer(customerDoc.ref);
        }
      } else {
        create = true;

        if (searchParams !== null && searchParams.has('p')) {
          customerParentDoc = await getCustomerById(searchParams.get('p'));
          customerParentRef = customerParentDoc.ref;
        } else if (customerParentRef) {
          customerParentDoc = await customerParentRef.get();
        }
      }

      if (customerParentDoc) {
        customerParentData = customerParentDoc.data();
      }

      if (customerDocData.contact && customerDocData.contact.image) {
        setContactPersonImageSrc(customerDocData.contact.image);
      }

      if (customerDocData.brand && customerDocData.brand.qrLogo) {
        setBrandQrLogoImageSrc(customerDocData.brand.qrLogo);
      }

      if (customerPackages.length > 0) {
        maxUserCount = customerPackages
          .map((p) => (p.active ? p.maxAccounts : 0))
          .reduce((p, n) => p + n);
      }

      // reset used to init form with loaded data
      reset({ ...customerDocData });

      setMaxUserCount(maxUserCount);
      setActiveUserCount(activeUserCount);
      setIsCreate(create);
      setCustomerRef(customerDoc);
      setCustomerData(customerDocData);
      setParentRef(customerParentRef);
      setParentData(customerParentData);
      setPackages(customerPackages);
      setLoading(false);

      if (searchParams !== null && searchParams.has('showUserNotice')) {
        setShowMaxUserPopup(true);
      }
    };

    load();
  }, [id, location]);

  /**
   * Save customer form data
   * @param {*} data
   */
  const save = async (data) => {
    const hasErrors = Object.keys(errors).length > 0;

    if (!hasErrors) {
      setLoading(true);

      const updateResult = await CustomerService.processFormData({
        isCreate,
        customerData,
        customerRef,
        formData: data,
        contactImage: contactPersonImageSrc,
        brandImage: brandQrLogoImageSrc,
        parentRef
      });

      if (updateResult !== false) {
        // Update Packages
        await updateCustomerPackages(packages);

        // handle logins for packages that where deleted while editing
        for (let i = 0; i < packages.length; i += 1) {
          const customerPackage = packages[i];

          const loginType = customerPackage.type.toLowerCase().includes('leak')
            ? 'leak'
            : 'pressure';

          const usersOfPackage = await getUsersByPackage(customerPackage.ref, loginType);

          // handle deactivated packages and corresponding users
          if (customerPackage.active === false) {
            await CustomerService.handleUsersOfArchivedPackage(
              customerPackage,
              customerData,
              usersOfPackage
            );
          }

          // handle change of valid until from package and synch with logins of users
          await CustomerService.handlePackageValidUntilChanges(customerPackage, usersOfPackage);
        }

        await updateParent(updateResult.id, updateResult.data);

        // @TODO capture if error or not
        // @TODO capute which type/stage caused the error
        // @TODO only proceed when no error
        reset({ ...updateResult.data });

        setCustomerData(updateResult.data);
        setCustomerRef(updateResult.doc);
        setIsCreate(false);
        setLoading(false);

        history.push(`/customer/${updateResult.id}`);
      } else {
        setLoading(false);
        setShowErrorPopup(true);
      }
    }
  };

  const cancel = () => {
    history.push('/customer/list');
  };

  const archiveCustomer = async () => {
    const update = { ...customerData };

    update.archived = true;

    // disable all packages
    const updatedArchivedPackages = packages.map((p) => {
      p.active = false;
      return p;
    });

    await updateCustomerPackages(packages);

    for (let i = 0; i < updatedArchivedPackages.length; i += 1) {
      const customerPackage = updatedArchivedPackages[i];
      const loginType = customerPackage.type.toLowerCase().includes('leak') ? 'leak' : 'pressure';
      const usersOfPackage = await getUsersByPackage(customerPackage.ref, loginType);
      await CustomerService.handleUsersOfArchivedPackage(
        customerPackage,
        customerData,
        usersOfPackage
      );
    }

    setShowArchivePopup(false);
    FirebaseService.upsertDoc(customerRef.id, update, Collections.customer);
    history.push(`/customer/${customerRef.id}`);
  };

  /**
   * Handle actions form "more" menu
   * @TODO capsulate actions
   * @param {*} action
   */
  const handleMoreAction = async (action) => {
    if (action === 'showtree') {
      setShowTreeGraphModal(true);
    }

    if (action === 'addcustomer') {
      history.push(`/customer/new?p=${customerRef.id}`);
    }

    if (action === 'showUsersOfCustomer') {
      updateFilter({ customerName: customerData.name1, firstname: '', lastname: '', email: '' });
      history.push(`/user/list`);
    }

    if (action === 'adduser') {
      if (activeUserCount < maxUserCount) {
        history.push(`/user/new?c=${customerRef.id}`);
      } else {
        setShowMaxUserPopup(true);
      }
    }

    if (action === 'generatereport') {
      history.push(`/customer/report/${customerRef.id}`);
    }

    if (action === 'disablecustomer') {
      setShowArchivePopup(true);
    }

    if (action === 'enablecustomer') {
      const update = { ...customerData };

      update.archived = false;

      FirebaseService.upsertDoc(customerRef.id, update, Collections.customer);
      history.push(`/customer/${customerRef.id}`);
    }
  };

  /**
   * Update given packages data
   * @param {*} packageData
   */
  const updatePackage = (packageData) => {
    const update = [...packages];
    if (packageData.id) {
      const index = update.findIndex((p) => p.id === packageData.id);
      update[index] = { ...update[index], ...packageData };
    } else {
      // @TODO fix it
      // eslint-disable-next-line no-param-reassign
      packageData.active = true;
      // eslint-disable-next-line no-param-reassign
      packageData.customer = customerRef.ref;

      update.push(packageData);
    }

    setPackages(update);
    setShowPackageModal(false);
  };

  const renderConfirmLeaveModal = (onConfirm, onCancel) => {
    return (
      <Popup
        show
        size={400}
        title={tn('general.popup.unsaved.title')} // "Ungespeicherte Daten"
        message={tn('general.popup.unsaved.text')} // "Möchten Sie die ungeänderten Daten verwerfen"
        confirmColor="red"
        confirmText={tn('general.popup.unsaved.buttonYes')} // "Ja"
        confirmAction={onConfirm}
        abortAction={onCancel}
        abortText={tn('general.popup.unsaved.buttonNo')} // "Nein"
        abortColor="green"
      />
    );
  };

  const getCustomerName = () => {
    if (!isCreate && customerRef && customerData) {
      return `#${customerRef.id.substring(0, 5).toUpperCase()} - ${customerData.name1}`;
    }

    return '';
  };

  return (
    <LoadingBox
      loading={loading}
      renderChildren={!loading}
      minHeight="calc(100vh - 143px)"
      height="100%"
      width="100%"
    >
      {customerData && (
        <>
          <Banner
            text={
              isCreate
                ? tn('admin.page.customerForm.title.add')
                : tn('admin.page.customerForm.title.edit')
            }
            // text={`Kunde ${isCreate ? 'anlegen' : 'bearbeiten'}`}
            subtext={getCustomerName()}
          />
          <Box paddingX={10} paddingY={10}>
            {customerData.archived && (
              <TeaserBox>
                <Text size="sm" bold color="purple">
                  {tn('admin.page.customerForm.archivedNotice')}
                  {/* Dieser Kunde ist archiviert */}
                </Text>
              </TeaserBox>
            )}

            {!isCreate && customerData.archived !== true && (
              <TeaserBox>
                <Text size="sm" bold color="purple">
                  {activeUserCount} / {maxUserCount}{' '}
                  {tn('admin.page.customerForm.activeUserNumbers')}
                </Text>
                <Info infoText={tn('admin.page.customerForm.activeUserNote')} bubbleWidth={300} />
              </TeaserBox>
            )}

            <form onSubmit={handleSubmit(save)} noValidate>
              <CustomerFormGeneral
                register={register}
                errors={errors}
                customerData={customerData}
                customerRef={customerRef}
                isCreate={isCreate}
                canEditType={isCreate || AppStateService.isLOOXR()}
                parentData={parentData}
                showMoreMenu={true}
                onMoreActionClick={handleMoreAction}
              />

              <CustomerFormContact
                register={register}
                errors={errors}
                customerData={customerData}
                imageSrc={contactPersonImageSrc}
                onImageUpdate={(base64) => setContactPersonImageSrc(base64)}
              />

              <CustomerFormParams
                clearError={clearError}
                watch={watch}
                register={register}
                errors={errors}
                customerData={customerData}
              />

              {customerRef && customerRef.id && (
                <CustomerFormPackages
                  packages={packages}
                  customerData={customerData}
                  onDelete={(data) => updatePackage({ ...data, active: false })}
                  onEdit={(data) => {
                    setEditPackage(data);
                    setShowPackageModal(true);
                  }}
                  onCreate={() => {
                    setEditPackage({});
                    setShowPackageModal(true);
                  }}
                />
              )}

              {!isCreate && AppStateService.isLOOXR() && (
                <CustomerFormBrand
                  customerData={customerData}
                  imageSrc={brandQrLogoImageSrc}
                  onImageUpdate={(base64) => setBrandQrLogoImageSrc(base64)}
                />
              )}

              <CustomerFormPrivacy
                register={register}
                errors={errors}
                customerData={customerData}
              />

              {/*
                <CustomerFormTableau
                  register={register}
                  errors={errors}
                  customerData={customerData}
                />
              */}

              <StyledFormButtonBox paddingX={0} paddingY={5} width={400} marginTop={10}>
                <Button
                  disabled={customerData.archived}
                  text={tn('general.save')} // "Speichern"
                  type="submit"
                />
                <Button
                  text={tn('general.cancel')} // "Abbrechen"
                  background="red"
                  onClick={() => cancel()}
                />
              </StyledFormButtonBox>
            </form>
          </Box>
        </>
      )}

      <Alert
        show={showErrorPopup}
        title={tn('general.error')}
        message={tn('admin.page.userForm.errorOnSaveNotice')}
        btnText={tn('general.ok')}
        btnAction={() => setShowErrorPopup(false)}
      />

      <Alert
        show={showMaxUserPopup}
        title={tn('general.notice')}
        message={tn('admin.page.customerForm.maxUserNotice')}
        btnText={tn('general.ok')}
        btnAction={() => setShowMaxUserPopup(false)}
      />

      <Popup
        show={showArchivePopup}
        title={tn('admin.popup.archiveCustomer.title')}
        message={tn('admin.popup.archiveCustomer.text')}
        confirmText={tn('admin.popup.archiveCustomer.buttonYes')}
        abortText={tn('admin.popup.archiveCustomer.buttonNo')}
        confirmColor="green"
        abortColor="red"
        confirmAction={() => archiveCustomer()}
        abortAction={() => setShowArchivePopup(false)}
      />

      <Modal
        show={showPackageModal}
        size={600}
        closeOnBackdrop={false}
        closeOnEsc={false}
        showModalCloseIcon={false}
        onClose={() => setShowPackageModal(false)}
      >
        <CustomerPackageForm
          packageData={editPackage}
          customerData={customerData}
          onSave={(data) => updatePackage(data)}
          onCancel={() => setShowPackageModal(false)}
        />
      </Modal>

      <Modal
        show={showTreeGraphModal}
        size={1200}
        closeOnBackdrop={true}
        closeOnEsc={true}
        showModalCloseIcon={true}
        onClose={() => setShowTreeGraphModal(false)}
      >
        <CustomerTreeGraph customer={customerData} parent={parentData} />
      </Modal>

      <NavigationPrompt when={formState.dirty}>
        {({ onConfirm, onCancel }) => renderConfirmLeaveModal(onConfirm, onCancel)}
      </NavigationPrompt>
    </LoadingBox>
  );
}

export default CustomerForm;
